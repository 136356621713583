import { template as template_895c3a243f8b4711a6bc8ec917b99313 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_895c3a243f8b4711a6bc8ec917b99313(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
