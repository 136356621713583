import { template as template_cee86fab13e5447b85e33f79fe761e3a } from "@ember/template-compiler";
const SidebarSectionMessage = template_cee86fab13e5447b85e33f79fe761e3a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
