import { template as template_9666c60258f340ae93f35af2c73212a9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_9666c60258f340ae93f35af2c73212a9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
