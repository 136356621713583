import { template as template_7648c46454cd49418c6ea83f9ab98778 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7648c46454cd49418c6ea83f9ab98778(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
